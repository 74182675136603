<template>
  <div id="gallery">
    <div id="gallery-wrapper">
      <div class="gallery-panel" v-for="photo in this.pictureList" :key="photo">
        <router-link :to="`/photos/${photo}`">
          <img :src="this.loadImg(photo)" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      pictureList: [
        "one.jpg",
        "two.jpg",
        "three.jpg",
        "four.jpg",
        "five.jpg",
        "six.jpg",
        "seven.jpg",
        "eight.jpg",
        "nine.jpg",
        "ten.jpg",
        "eleven.jpg",
        "twelve.jpg",
        "thirteen.jpg",
        "fourteen.jpg",
      ],
    };
  },
  methods: {
    loadImg(filename) {
      return require(`../assets/pictures/${filename}`);
    },
  },
};
</script>

<style scoped>
#gallery {
  background: linear-gradient(rgba(41, 31, 37, 0.85), rgba(41, 31, 37, 0.85)),
    url("../assets/hongfulogored.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  padding: 2.5rem 0.5rem;
  min-height: calc(100vh - 165px);
}
#gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;
  margin-bottom: 45px;
}
.gallery-panel {
  flex-basis: 32%;
}
.gallery-panel:nth-child(3n) {
  padding-right: unset;
}
.gallery-panel img {
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
}
.gallery-panel img:hover {
  filter: brightness(50%);
  transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
}

@media only screen and (max-width: 600px) {
  #gallery {
    flex-grow: 1;
    min-height: calc(100vh - 255px);
  }
  .gallery-panel {
    height: 65vw;
    flex: 1 1 100%;
  }
}
</style>
